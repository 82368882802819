<template>
   <div class="modal-cart">
      <div :data-state="emptyCart" class="modal-cart__inner">
         <product-card v-for="product in products" :key="product.id" :product="product" />
      </div>

      <cart-footer />
   </div>
</template>

<script>
   import ProductCard from './modules/ProductCard';
   import CartFooter from './modules/CartFooter';
   import { mapState, mapGetters } from 'vuex';

   export default {
      name: 'ModalCart',

      components: {
         ProductCard,
         CartFooter
      },

      emits: {
         'update:title': (value) => typeof value === 'string'
      },

      beforeMount() {
         this.$emit('update:title', 'Корзина');
      },

      computed: {
         ...mapState('cart', ['products']),
         ...mapGetters('cart', ['productsCount']),

         emptyCart() {
            return this.productsCount ? null : 'Пустая корзина';
         }
      }
   };
</script>
