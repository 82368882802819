<template>
   <article class="product">
      <div class="product__inner">
         <img :src="product.image" :alt="product.imageDescription" class="product__img" />
      </div>

      <div class="product__wrap">
         <h3 class="product__title">
            <router-link
               :to="{ name: 'Product', params: { id: product.id } }"
               @click="closeModal"
               class="product__link"
            >
               {{ product.name }}
            </router-link>
         </h3>

         <p class="product__description">{{ productDescription }}</p>

         <div class="product__wrapper">
            <div class="product__count-wrap count-wrap">
               <button
                  @click="decreaseProductsCount(product)"
                  class="count-wrap__decrease btn"
               ></button>

               <input
                  v-model="productsCount"
                  @input="setProductsCount(product)"
                  class="count-wrap__input input"
                  type="text"
               />

               <button
                  @click="increaseProductsCount(product)"
                  class="count-wrap__increase btn"
               ></button>
            </div>

            <p class="product__price">{{ totalPrice }}&nbsp;{{ currency }}</p>
         </div>
      </div>

      <button @click="removeProductFromCart(product)" class="product__btn btn">
         <remove-icon class="product__remove-icon" />
      </button>
   </article>
</template>

<script>
   import RemoveIcon from '@/components/Icons/RemoveIcon';
   import { mapActions } from 'vuex';

   export default {
      name: 'ProductCard',

      components: {
         RemoveIcon
      },

      props: {
         product: { type: Object, required: true }
      },

      inject: ['closeModal'],

      data() {
         return {
            productsCount: 0,
            maxStrLength: 250
         };
      },

      methods: {
         ...mapActions('cart', [
            'saveProductState',
            'saveProductCountState',
            'updateProductCountState'
         ]),

         removeProductFromCart(product) {
            this.saveProductState({ product, isRemove: true });
         },

         increaseProductsCount(product) {
            this.saveProductCountState({ product, isIncrease: true });
         },

         decreaseProductsCount(product) {
            if (product.count > 1) {
               this.saveProductCountState({ product, isIncrease: false });
            } else {
               this.saveProductState({ product, isRemove: true });
            }
         },

         setProductsCount(product) {
            const value = +this.productsCount.replace(/\D/g, (match, offset, string) =>
               string.length > 1 ? '' : 1
            );

            if (value >= 1) {
               this.updateProductCountState({ product, value });
               this.productsCount = value;
            } else {
               this.saveProductState({ product, isRemove: true });
            }
         }
      },

      computed: {
         productDescription() {
            const { description } = this.product;

            return description.length < this.maxStrLength
               ? description
               : `${description.slice(0, this.maxStrLength)} ...`;
         },

         totalPrice() {
            return this.product.price * this.product.count;
         }
      },

      watch: {
         'product.count': {
            handler(value) {
               this.productsCount = value;
            },
            immediate: true
         }
      }
   };
</script>
